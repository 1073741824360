window._ = require('lodash');

window.$ = window.jQuery = window.jquery = require('jquery/');
require('popper.js/dist/popper.min');
require('../static-vendor/bootstrap/dist/js/bootstrap.min');
require('moment/dist/moment');
require('jquery-ui/ui/core');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/autocomplete');
require('metismenu/dist/metisMenu.min');
require('bootstrap4-toggle/js/bootstrap4-toggle.min');
require('jquery-circle-progress/dist/circle-progress.min');
window.PerfectScrollbar = require('perfect-scrollbar/dist/perfect-scrollbar.min');
window.toastr = require('toastr/build/toastr.min');
window.ApexCharts = require('apexcharts/dist/apexcharts.min');
require('bootstrap-table/dist/bootstrap-table.min');
require('datatables.net/js/jquery.dataTables.min');
require('datatables.net-bs4/js/dataTables.bootstrap4.min');
require('datatables.net-responsive/js/dataTables.responsive.min');
require('datatables.net-responsive-bs4/js/responsive.bootstrap4.min');
require('slick-carousel/slick/slick.min');
require('select2/dist/js/select2.full.min');
require('./charts/apex-charts');
require('./circle-progress');
require('./demo');
require('./scrollbar');
require('./toastr');
require('./treeview');
require('./form-components/toggle-switch');
require('./tables');
require('./carousel-slider');
require('./app2');
